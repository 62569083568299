<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import StarRating from "vue-star-rating";
// import axios from "axios";

export default {
  page: {
    title: "Drivers Rides",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, StarRating },
  data() {
    return {
     
      star: null,
      title: "Driver Rides",
      items: [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Drivers",
          href: "JavaScript:void(0)"
        },
        {
          text: "Driver Rides",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        { key: "rider_number", label: 'Rider', sortable: true },
        { key: "createdAt", label: 'Booking Date', sortable: true },
        { key: "pick_up_at", label: 'Pick Up At', sortable: true },
        // { key:"dropoff_time",label:'Dropoff Time', sortable: true},
        { key: "no_of_hours", label: 'No of hours', sortable: true },
        { key: "fare", label: 'Fare', sortable: true },
        { key: "status", label: 'Ride Status', sortable: true },
      ]
    }
  },

  methods: {
    convertDate(date) {
      if (date) {
        let newDate = new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
        return newDate
      }
    },

    convertTime(time) {
      if (time) {
        let newTime = new Date(time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
        return newTime
      }
    },

    // findRider(id) {
    //   let myRider = this.riders.find(obj => obj._id === id)
    //   return myRider.phone_number
    // }
  },

  mounted() {
  
    this.$store.dispatch('driver/getDriversforDetail')
    this.$store.dispatch('userRides/getRides',{id:this.$route.params.id,userType:'driver'})
   

    if (this.driver.rating === 0) {
      this.star = 0
    } else {
      this.star = this.driver.rating / this.driver.completed_rides
    }


  },

  computed:{
    driver(){
      return this.$store.getters['driver/driverGetter'](this.$route.params.id)
    },

    rides(){
      return this.$store.getters['userRides/ridesGetter']
    },

    rows() {
      return this.rides.length
    }
  },

  watch:{
    '$route.params.id'(newVal,oldVal){
      console.log(oldVal)
      if(this.$route.path.includes('driver-rides')){
        this.$store.dispatch('userRides/getRides',{id: newVal,userType:'driver'})
      }
    }
  }
}

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
   
    <div class="card profile-card">
      <div class="card-body">
        <img alt="200x200" v-if="driver.photo_url !== null" :src="driver.photo_url" data-holder-rendered="true"
          class="img-thumbnail rounded-circle avatar-xl">
        <img alt="200x200" v-if="driver.photo_url === null" src='../../../assets/images/users/dummy-avatar.jpg'
          data-holder-rendered="true" class="img-thumbnail rounded-circle avatar-xl">

        <div class="profile-text">
          
          <h5 class="mb-0">{{ driver.first_name }} {{ driver.last_name }}</h5>
          <div class="stars">
            <div class="text-center">
              <star-rating :rating=star :read-only="true" :star-size="15"></star-rating>
            </div>
          </div>
        </div>
        <!-- <p :class="{'text-success profile-status':driver.status.active_status === 'active','text-danger profile-status':driver.status.active_status === 'inactive' }"><i class="ri-checkbox-blank-circle-fill"></i> {{ driver.status.active_status }}</p> -->
        <!--        <a href="javascript: void(0);" target="_self" class="btn btn-danger profile-button">De-Activate</a>-->
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Rides</h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="rides" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                @filtered="onFiltered">

                <template v-slot:cell(status)="row">
                  <div class="badge font-size-12"
                    :class="{ 'badge-soft-danger': `${row.value}` === 'cancelled' || `${row.value}` === 'expired', 'badge-soft-success': `${row.value}` === 'completed', 'badge-soft-warning': `${row.value}` === 'pending', 'badge-soft-secondary': `${row.value}` === 'scheduled', 'badge-soft-primary': `${row.value}` === 'Driver on the way', 'badge-soft-info': `${row.value}` === 'Driver at ride location', 'badge-soft-dark': `${row.value}` === 'Ride started', 'badge-soft-dark': `${row.value}` === 'Ride ended' }">
                    {{ row.value }}
                  </div>
                </template>



                <template v-slot:cell(createdAt)="date">
                  {{ convertDate(date.value) }}
                </template>

                <template v-slot:cell(pick_up_at)="pickUp">
                  {{ convertDate(pickUp.value) }} {{ convertTime(pickUp.value) }}
                </template>

                <!--                <template v-slot:cell(pick_up_at)="pickUpTime">-->
                <!--                  {{convertTime(pickUpTime.value)}}-->
                <!--                </template>-->

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<style scoped>
.profile-card {
  position: relative;
}

.profile-text {
  position: absolute;
  top: 36%;
  left: 165px;
}

.profile-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.profile-status {
  position: absolute;
  top: 10px;
  right: 10px;
}

.profile-status i {
  font-size: 12px !important;
}
</style>